<template>
    <!--<v-card raised class="size lessPad">-->
    <v-chip filter outlined large>
        <v-card flat class="d-flex align-start flex-column transparentBackground" style="padding: 0 8px;">
            <v-card flat class="transparentBackground">{{accountData.accountName.charAt(0).toUpperCase() + accountData.accountName.slice(1)}}</v-card>
            <v-card flat class="transparentBackground">{{accountData.accountCurrency}} {{formattedAccountAmount}}</v-card>
        </v-card>
    </v-chip>
    <!--</v-card>-->
</template>
<script>

  import { globalFunctions } from '../../../javascript/globalFunctions'

  export default {
    props: ['accountData'],
    data() {
      return {
          formattedAccountAmount: ''
      }
    },
    methods: {
        accountClicked: function () {
            alert('Account Clicked')
        },
        formatAccountAmount() {
            this.formattedAccountAmount = globalFunctions.formatMoney(this.accountData.accountAmount, 2, ".", ",")
        }
    },
        beforeMount() {
            this.formatAccountAmount()
        }
  }
</script>

<style scoped>
  .roundSides {
    border-radius: 25px !important;
  }
  .size {
    max-width: 250px;
    max-height: 100px;
  }

  .lessPad{
    padding: 0 12px !important;
  }

</style>
